.team_card {
  padding: 16px 24px;
  background-color: var(--colorSurface5);
  max-width: 300px;
  border-radius: 16px;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}

.team_img {
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.team_text {
  font-size: var(--titleMedium);
  color: var(--colorOnBackground);
  text-align: center;
}