.member_master {
  width: 100%;
  /* gap: 16px; */
  flex-direction: column;
}

.table_wrapper {
  flex-direction: column;
  border-radius: 16px;
  background-color: var(--colorSurface1);
  padding: 16px;
  width: calc(100vw - var(--sidebarWidth) - 53px);
  overflow: scroll;
  /* height: 100%;
  gap: 24px; */
}

.th,
.td {
  height: 80px !important;
  width: 131.56px !important;
  padding: 8px 16px !important;
  vertical-align: top !important;
  white-space: normal;
}

Td{
  padding: 12px 16px !important;
}

.fs14 {
  font-size: var(--bodyMedium);
  color: var(--colorOnSurface);
  font-weight: 400;
}

.icon {
  font-size: 24px;
  color: var(--colorOnSurface);
}

@media screen and (max-width: 500px) {
  .table_wrapper {
    width: calc(100vw - 48px);
  }
}