.task_setting {
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.task_setting_content {
  padding: 24px 16px;
  border-radius: 16px;
  background-color: var(--colorSurface1);
  gap: 16px;
}

.task_setting_content_left {
  justify-content: space-between;
  height: min-content;
  align-items: center;
  width: 40%;
  gap: 16px;
}

.task_setting_content_right {
  flex-direction: column;
  gap: 24px;
  width: 60%;
}

/* divider */
.divider_horizontal {
  display: none;
}

/* fonts */
.fs16_500 {
  font-size: var(--bodyLarge);
  font-weight: 500;
  color: var(--colorOnSurface);
}
.fs14_500 {
  font-size: var(--bodyMedium);
  font-weight: 500;
  color: var(--colorOnSurface);
}
.fs14_400 {
  font-size: var(--bodyMedium);
  font-weight: 400;
  color: var(--colorOnSurface);
}
.italic {
  font-style: italic;
}
.bold {
  font-weight: bold;
}


/* icons */
.icon {
  font-size: 24px;
  color: var(--colorOnSurface);
}

.block {
  display: block;
}

.pointer {
  cursor: pointer;
}

@media screen and (max-width: 720px) {
  .task_setting_content {
    flex-direction: column;
  }
  .task_setting_content_left,
  .task_setting_content_right {
    width: 100%;
  } 
  .divider_horizontal {
    display: block;
  }
  .divider_vertical {
    display: none;
  }
}