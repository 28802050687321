.button {
  font-size: var(--bodyMedium) !important;
  font-weight: 500 !important;
  border-radius: 12px !important;
  padding: 13px 28px !important;
  height: 48px !important;
}

.icon {
  font-size: 24px !important;
}

/* width */
.full {
  width: 100%;
}
.fit {
  width: max-content;
}

/* default */
.default {
  background-color: var(--colorPrimary) !important;
  color: var(--colorSurface) !important;
}
.default:hover {
  background-color: var(--colorPrimary70) !important;
}
.default:focus {
  background-color: var(--colorPrimary60) !important;
}
.default:active {
  background-color: var(--colorPrimary60) !important;
}

/* outline */
.outline {
  background-color: transparent !important;
  border: 1px solid var(--colorPrimary) !important;
  color: var(--colorPrimary) !important;
}
.outline:hover {
  background-color: var(--colorSurface2) !important;
}
.outline:focus {
  background-color: var(--colorSurface3) !important;
}
.outline:active {
  background-color: var(--colorSurface3) !important;
}

/* text */
.text {
  background-color: transparent !important;
  color: var(--colorPrimary) !important;
}
.text:hover {
  background-color: var(--colorSurface2) !important;
}
.text:focus {
  background-color: var(--colorSurface3) !important;
}
.text:active {
  background-color: var(--colorSurface3) !important;
}

/* tonal */
.tonal {
  background-color: var(--colorPrimaryContainer) !important;
  color: var(--colorPrimary) !important;
}
.tonal:hover {
  background-color: var(--colorPrimary40) !important;
}
.tonal:focus {
  background-color: var(--colorPrimary50) !important;
}
.tonal:active {
  background-color: var(--colorPrimary50) !important;
}

/* elevated */
.elevated {
  background-color: var(--colorPrimary) !important;
  color: var(--colorSurface) !important;
}
.elevated:hover {
  background-color: var(--colorPrimary70) !important;
}
.elevated:focus {
  background-color: var(--colorPrimary60) !important;
}
.elevated:active {
  background-color: var(--colorPrimary60) !important;
}