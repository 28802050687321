.last_location {
  width: 100%;
  flex-direction: column;
  min-height: calc(100vh - 32px - var(--sidebarHeight));
  gap: 16px;
}

.content_wrapper {
  height: calc(100vh - var(--headerHeight) - 33px - 16px - 24px - 32px - 48px);
  gap: 24px;
}

.member_wrapper, .map_wrapper {
  flex-direction: column;
  border-radius: 16px;
  background-color: var(--colorSurface1);
  padding: 24px;
  height: 100%;
  gap: 24px;
}

.member_wrapper {
  width: 30%;
}

.map_wrapper {
  width: 70%;
}

.member_list {
  flex-direction: column;
  overflow: scroll;
  gap: 12px;
}

.text {
  font-size: var(--bodyMedium);
  color: var(--colorOnSurface);
}

.text_sm {
  font-size: var(--bodySmall);
  color: var(--colorOnSurface);
  font-weight: 300;
}

.icon {
  font-size: 24px;
  color: var(--colorOnSurface);
  font-weight: 300;
}

.pointer {
  cursor: pointer;
}

@media screen and (max-width: 720px) {
  .content_wrapper {
    height: 100%;
    flex-direction: column;
    overflow: scroll;
  }

  .member_wrapper, .map_wrapper {
    width: 100%;
    height: calc(100vh - var(--headerHeight) - 32px - 33px - 32px - 33px - 16px);
  }
}