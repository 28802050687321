.member_item {
  width: 100%;
  gap: 12px;
  cursor: pointer;
  align-items: center;
}

.name_email {
  color: var(--colorOnSurface);
  flex-direction: column;
}

.name {
  font-size: var(--bodyMedium);
}

.email {
  font-size: var(--bodySmall);
}