.loading:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

    0%,
    20% {
        color: var(--colorOnBackground);
        text-shadow:
            .25em 0 0 var(--colorOnBackground),
            .5em 0 0 var(--colorOnBackground);
    }

    40% {
        color: var(--colorBackground);
        text-shadow:
            .25em 0 0 var(--colorOnBackground),
            .5em 0 0 var(--colorOnBackground);
    }

    60% {
        text-shadow:
            .25em 0 0 var(--colorBackground),
            .5em 0 0 var(--colorOnBackground);
    }

    80%,
    100% {
        text-shadow:
            .25em 0 0 var(--colorBackground),
            .5em 0 0 var(--colorBackground);
    }
}