.attendance_monitoring {
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

.controls {
  justify-content: space-between;
  align-items: center;
}

.control_date {
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
}

.fs14 {
  font-size: var(--bodyMedium);
  color: var(--colorOnSurface);
  font-weight: 500;
}

.fs14_400 {
  font-size: var(--bodyMedium);
  color: var(--colorOnSurface);
  font-weight: 400;
}

.fs16 {
  font-size: var(--bodyLarge);
  color: var(--colorOnSurface);
  font-weight: 500;
}

.since {
  display: none;
}

.table_wrapper {
  flex-direction: column;
  border-radius: 16px;
  background-color: var(--colorSurface1);
  padding: 16px;
  width: calc(100vw - var(--sidebarWidth) - 48px);
  overflow: scroll;
  /* height: 100%;
  gap: 24px; */
}

.th,
.td {
  width: 131.56px !important;
  padding: 16px !important;
  vertical-align: top !important;
  white-space: normal;
}

/* .td {
  padding: 8px !important;
} */

@media screen and (max-width: 500px) {
  .table_wrapper {
    width: calc(100vw - 48px);
  }
}

@media screen and (max-width: 760px) {
  .controls {
    flex-direction: column;
    gap: 16px;
  }

  .control_date {
    width: 100%;
  }

  .searchbar {
    margin-left: auto;
  }
}

@media screen and (max-width: 1080px) {
  .controls {
    align-items: baseline;
  }

  .control_date {
    flex-direction: column;
    gap: 8px;
    align-items: end;
  }

  .since {
    display: block;
  }

  .control_date_separator {
    display: none;
  }
} 