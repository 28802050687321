* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}

:root {
  /* colors */
  --colorPrimary: #0060A9;
  --colorPrimary40: #0060A9;
  --colorPrimary50: #0079D2;
  --colorPrimary60: #3994EF;
  --colorPrimary70: #6BAEFF;

  --colorOnPrimary: #FFFFFF;
  --colorPrimaryContainer: #D3E4FF;
  --colorOnPrimaryContainer: #001C38;

  --colorSecondary: #545F70;
  --colorOnSecondary: #FFFFFF;
  --colorSecondaryContainer: #D7E3F8;
  --colorOnSecondaryContainer: #111C2B;

  --colorTertiary: #6C5677;
  --colorOnTertiary: #FFFFFF;
  --colorTertiaryContainer: #F5D9FF;
  --colorOnTertiaryContainer: #261431;

  --colorError: #BA1A1A;
  --colorOnError: #FFFFFF;
  --colorErrorContainer: #FFDAD6;
  --colorOnErrorContainer: #410002;

  --colorBackground: #FDFCFF;
  --colorOnBackground: #1A1C1E;

  --colorSurface: #FDFCFF;
  --colorOnSurface: #1A1C1E;

  --colorSurfaceVariant: #DFE2EB;
  --colorOnSurfaceVariant: #43474E;
  --outline: #73777F;

  --colorSurface1: #f0f4fa;
  --colorSurface2: #e9f0f9;
  --colorSurface3: #e1ebf6;
  --colorSurface4: #dee9f4;
  --colorSurface5: #d9e5f3;



  /* font size */
  --displayLarge: 57px;
  --displayMedium: 45px;
  --displaySmall: 36px;

  --headlineLarge: 32px;
  --headlineMedium: 28px;
  --headlineSmall: 24px;

  --titleLarge: 22px;
  --titleMedium: 16px;
  --titleSmall: 14px;

  --labelLarge: 14px;
  --labelMedium: 12px;
  --labelSmall: 11px;

  --bodyLarge: 16px;
  --bodyMedium: 14px;
  --bodySmall: 12px;



  /* sidebar */
  --sidebarWidth: 248px;
  --sidebarUnreveal: 84.4px;
  --sidebarPadding: 16px;
  --sidebarMobile: -100%;



  /* header */
  --headerHeight: 56px;
  --headerPadding: 8px 24px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: var(--colorSurface5);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.css-uo84k3 {
  font-size: 12px !important;
}

/* header language dropdown */
.header_profile_dropdown_content__e4WCr .css-r6z5ec {
  left: -100% !important;
  margin-left: -8px !important;
  margin-top: -48px !important;
}

@media screen and (max-width: 500px) {
  /* header language dropdown */
  .header_profile_dropdown_content__e4WCr .css-r6z5ec {
    left: 0 !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
  }
}

/* react paginate */
.react-paginate-pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 20px;
}

.react-paginate-page-item {
  color: var(--chakra-colors-primary) !important;
  min-width: 30px;
  min-height: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 4px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.react-paginate-page-item-previous,
.react-paginate-page-item-next {
  color: var(--chakra-colors-primary) !important;
  min-width: 30px;
  min-height: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 4px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--chakra-colors-onBackground);
}

.react-paginate-page-item:hover,
.react-paginate-page-item-previous:hover,
.react-paginate-page-item-next:hover {
  background-color: var(--chakra-colors-surfacePlus5);
}

.react-paginate-page-item-active .react-paginate-page-item {
  background-color: var(--chakra-colors-onPrimary) !important;
  color: var(--chakra-colors-primary) !important;
  border-radius: 4px;
}
/* end react paginate */