.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--headerHeight);
  padding: var(--headerPadding);
  background-color: var(--colorSurface);
  overflow-y: scroll;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  overflow: visible;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3);
  z-index: 999;
}

.hamburger {
  display: none !important;
}

.header_logo {
  height: 39.69px;
  cursor: pointer;
}

.header_logo_name {
  display: block !important;
  margin-top: 6px;
}

.header_logo_only {
  height: 24px;
  display: none !important;
}

.header_button {
  height: 40px;
  width: 40px;
  background-color: var(--colorSurface1) !important;
  color: var(--colorOnSurface) !important;
  border-radius: 12px !important;
}

.notification, .chat, .theme {
  font-size: 20px !important;
}

.language_dropdown {
  font-size: 16px !important;
  font-weight: normal !important;
  background-color: var(--colorSurface1) !important;
  color: var(--colorOnSurface) !important;
  padding: 8px 16px !important;
}

.profile_dropdown {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 8px 16px !important;
  background-color: var(--colorSurface1) !important;
  color: var(--colorOnSurface) !important;
  border-radius: 16px !important;
}

.profile_picture {
  height: 24px !important;
  width: 24px !important;
}

.profile_name {
  font-size: 16px !important;
  font-weight: normal !important;
  color: var(--colorOnSurface) !important;
}

.profile_dropdown_content {
  padding: 16px;
  background: var(--colorSurface1) !important;
  border: none;
  border-radius: 16px !important;
}

.profile_dropdown_content_item {
  padding: 8px !important;
  background: var(--colorSurface1);
  width: 100%;
  gap: 16px;
}

.profile_dropdown_content_item .header_button {
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3);
}

.logout {
  color: var(--colorError) !important;
}

.not_mobile_only {
  display: flex !important;
}

.mobile_only {
  display: none !important;
}

.language_list {
  position: absolute;
  left: calc(-100% + 8px) !important;
  top: -48px;
}

@media screen and (max-width: 720px) {
  .header {
    padding: 8px 12px;
  }

  .header_logo_name,
  .not_mobile_only {
    display: none !important;
  } 
  .header_logo_only {
    display: block !important;
  }
  .mobile_only {
    display: flex !important;
  }
}

@media screen and (max-width: 500px) {
  .hamburger {
    display: block !important;
  }

  .profile_name {
    display: none;
  }
}